import { IComplementCategory, IDatabaseComplementCategory } from "./IDatabaseComplementCategory"
import { IDatabaseFile, IFile } from "./IDatabaseFile"

export interface IDatabaseComplement {
    id: number
    name: string
    picture?: IDatabaseFile
    description?: string
    description_fr?: string
    price: number
    quantityInBox?: number
    baseIntakeQuantity?: number
    category?: IDatabaseComplementCategory
}

export class IComplement implements IDatabaseComplement {
    id: number;
    picture?: IFile;
    description?: string;
    description_fr?: string;
    name: string;
    price: number;
    quantityInBox?: number;
    baseIntakeQuantity?: number;
    category?: IComplementCategory;

    constructor(props: IDatabaseComplement) {
        this.id = props.id;
        this.picture = props.picture ? new IFile(props.picture) : undefined;
        this.description = props.description;
        this.description_fr = props.description_fr;
        this.name = props.name;
        this.price = props.price;
        this.quantityInBox = props.quantityInBox;
        this.baseIntakeQuantity = props.baseIntakeQuantity;
        this.category = props.category && new IComplementCategory(props.category);
    }
}