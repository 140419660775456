import { CartItem } from "./CartItem";

interface ICart<T extends { id: number | string, price: number }> {
    items: CartItem<T>[]
}

export class Cart<T extends { id: number | string, price: number }> implements ICart<T> {
    items: CartItem<T>[];

    constructor(props?: ICart<T>) {
        this.items = props?.items ?? [];
    }

    public addItem(newItem: T, quantity: number = 1): this {
        this.items = [
            ...this.items,
            new CartItem<T>({
                item: newItem,
                quantity: quantity
            })
        ];

        return this;
    }

    public removeItem(itemToRemove: T): this {
        this.items = this.items.filter(
            (cartItem) => cartItem.item.id !== itemToRemove.id
        );

        return this;
    }

    public getTotal(): number {
        let total = 0;

        this.items.forEach((item) => total += item.getTotal());

        return total;
    }
}