import { Modal } from "components/common/Modal";
import { Button } from "components/Form";
import { LanguageContext } from "context/Language/LanguageContext";
import { IComplement } from "interfaces/Database";
import { useContext } from "react";
import { useTranslation } from "services";

interface ComplementDetailModalProps {
    complement?: IComplement
    setComplement: React.Dispatch<React.SetStateAction<IComplement | undefined>>
}

const ComplementDetailModal: React.FC<ComplementDetailModalProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');
    const handleClose = () => props.setComplement(undefined);

    const { language } = useContext(LanguageContext);

    return <Modal
        isShow={props.complement !== undefined}
        toggleShow={handleClose}
        title={props.complement?.name}
        footer={
            <div className="flex-column full-width">
                <Button label={t.translate('close')} onClick={handleClose} />
            </div>
        }
    >
        {language.countryCode === 'FR' && props.complement?.description_fr
            ? props.complement.description_fr
            : props.complement?.description
        }
    </Modal>
}

export default ComplementDetailModal;